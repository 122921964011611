import React from "react"
import { BackButton } from '../components/Icons'

const Page = () => {

    const textStyle = {margin: 'auto', width: '75%'}

    return(
        <div className="base-layout">
            <div className="title">
                decision.ninja
            </div>
            <div className="large-text" style = {textStyle}>
                decision.ninja is currently in open alpha
            </div>
            <div className="large-text" style = {textStyle}>
                To get access to the open alpha, please join the <a href='https://discord.gg/wHrU2afRFn'>decision.ninja discord server</a>
                
            </div>
            <BackButton
                text="back"/>
        </div>
    )
}

export default Page